import ReactDOM from 'react-dom/client';

import '@/styles/main.css';

import { AuthProvider } from '@/contexts/AuthContext';
import { PrinterContext } from '@/contexts/PrinterContext';
import { ReactQueryProvider } from '@/contexts/QueryContext';
import { RouterProvider } from '@/contexts/RouterContext';
import { StoreProvider } from '@/contexts/StoreContext';

import { App } from '@/App';

import 'swiper/css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StoreProvider>
    <ReactQueryProvider>
      <RouterProvider>
        <AuthProvider>
          <PrinterContext>
            <App />
          </PrinterContext>
        </AuthProvider>
      </RouterProvider>
    </ReactQueryProvider>
  </StoreProvider>,
);
