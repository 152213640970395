import type { CreateSlice } from '@/contexts/StoreContext/store.types';

// =================================================================

export type MenuItem = {
  id: number;
  title: string;
  poster: string;
  count: number;
  price: number;
};

export type PickUpStates = {
  menus: Array<MenuItem>;
  totalPrice: number;
  increaseMenuItem: (item: MenuItem) => void;
  decreaseMenuItem: (itemId: number) => void;
  clearMenuList: VoidFunction;
};

export type PickUpStoreState = {
  pickUpStore: PickUpStates;
};

// =================================================================

export const createPickUpSlice: CreateSlice<PickUpStoreState> = set => ({
  pickUpStore: {
    menus: [],
    totalPrice: 0,
    increaseMenuItem: item =>
      set(state => {
        const currentMenuList = state.pickUpStore.menus;
        const existItemIndex = currentMenuList.findIndex(menu => menu.id === item.id);
        if (existItemIndex !== -1) {
          currentMenuList.splice(existItemIndex, 1, {
            ...currentMenuList[existItemIndex],
            count: currentMenuList[existItemIndex].count + 1,
          });
        } else {
          currentMenuList.push(item);
        }
        const currentTotalPrice = currentMenuList.reduce(
          (prev, acc) => prev + acc.price * acc.count,
          0,
        );
        return {
          pickUpStore: {
            ...state.pickUpStore,
            menus: currentMenuList,
            totalPrice: currentTotalPrice,
          },
        };
      }),
    decreaseMenuItem: itemId =>
      set(state => {
        const currentMenuList = state.pickUpStore.menus;
        const existItemIndex = currentMenuList.findIndex(menu => menu.id === itemId);
        if (existItemIndex !== -1) {
          currentMenuList.splice(existItemIndex, 1, {
            ...currentMenuList[existItemIndex],
            count: currentMenuList[existItemIndex].count - 1,
          });
        }

        const currentTotalPrice = currentMenuList.reduce(
          (prev, acc) => prev + acc.price * acc.count,
          0,
        );

        return {
          pickUpStore: {
            ...state.pickUpStore,
            menus: currentMenuList,
            totalPrice: currentTotalPrice,
          },
        };
      }),
    clearMenuList: () =>
      set(state => ({
        pickUpStore: {
          ...state.pickUpStore,
          menus: [],
          totalPrice: 0,
        },
      })),
  },
});
