import { MenuStatusEnum } from '@/enums/menu.enum';

import { useFavoriteList } from '@/pages/menu/favorite-list/useFavoriteList';

import { PickUpMenuCard } from '@/pages/pick-up/PickUpMenuCard';

export const FavoriteMenuList = () => {
  const { originData } = useFavoriteList();

  if (!originData) return <h3>Loading...</h3>;

  return (
    <div className="mt-8">
      <ul>
        {Object.keys(originData.list).map(key => (
          <PickUpMenuCard
            key={key}
            menu={{
              description: '',
              id: originData.list[key].menuId!,
              name: originData.list[key].name,
              price: originData.list[key].price!,
              photos: [''],
              status: MenuStatusEnum.INACTIVE,
              printer: false,
            }}
          />
        ))}
      </ul>
    </div>
  );
};
