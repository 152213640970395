import { create } from 'zustand';

import {
  type PickUpStoreState,
  createPickUpSlice,
} from '@/contexts/StoreContext/slices/pick-up.slices';

// =================================================================

export type BoundStore = PickUpStoreState;

// =================================================================

export const createBoundStore = create<BoundStore>()((...a) => ({
  ...createPickUpSlice(...a),
}));
