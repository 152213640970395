import { memo, Fragment, useState, useCallback } from 'react';

import { useAuthMethods } from '@/contexts/AuthContext';
import {
  TablePageLinks,
  CashPageLinks,
  MenuPageLinks,
  OrderPageLinks,
  PickUpPageLinks,
} from '@/contexts/RouterContext/router.links';

import { Button } from '@/components/Button';
import { Logo } from '@/components/Logo';
import { Modal } from '@/components/Modal';
import { NavLink } from '@/components/NavLink';

import { ReactComponent as CashSVG } from '@/assets/icons/cash.svg';
import { ReactComponent as ClocheSVG } from '@/assets/icons/cloche.svg';
import { ReactComponent as CoinsSVG } from '@/assets/icons/coins.svg';
import { ReactComponent as LogoutSVG } from '@/assets/icons/logout.svg';
import { ReactComponent as MenuSVG } from '@/assets/icons/menu.svg';
import { ReactComponent as TableSVG } from '@/assets/icons/table.svg';

// =================================================================

const menuConfig = [
  {
    title: 'Заказы',
    icon: <ClocheSVG />,
    href: OrderPageLinks.main + '?page=1&status=new',
  },
  {
    title: 'Сoбой',
    icon: <CoinsSVG />,
    href: PickUpPageLinks.main,
  },
  {
    title: 'Касса',
    icon: <CashSVG />,
    href: CashPageLinks.main,
  },
  {
    title: 'Столы',
    icon: <TableSVG />,
    href: TablePageLinks.main,
  },

  {
    title: 'Меню',
    icon: <MenuSVG />,
    href: MenuPageLinks.main,
  },

  // {
  //   title: 'Приход и уход',
  //   icon: <DocumentCheckmarkSVG />,
  //   href: ExpensePageLinks.main,
  // },
];

// =================================================================

export const Sidebar = memo(() => {
  const [isOpen, setIsOpen] = useState(false);

  const { logout } = useAuthMethods();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleConfirm = () => {
    try {
      logout();
      handleClose();
    } catch (error) {
      if (!error.response) {
        throw error;
      }
    }
  };

  return (
    <Fragment>
      <aside className="fixed left-0 top-0 z-20 flex h-screen w-20 flex-col justify-between border-r-2 border-gray bg-white pb-20">
        <div>
          <Logo />
          <ul>
            {menuConfig.map(menu => (
              <li key={menu.href}>
                <NavLink
                  to={menu.href}
                  className="flex flex-col items-center px-1.5 py-2 text-black-100"
                >
                  {menu.icon}
                  <span className="text-sm">{menu.title}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <button
          onClick={handleOpen}
          className="mx-auto flex w-fit flex-col items-center rounded-md border border-primary px-1.5 py-1 text-primary"
        >
          <LogoutSVG />
          <span className="text-sm">Выйти</span>
        </button>
      </aside>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <h3 className="my-6 text-center text-3xl font-semibold">Вы хотите выйти?</h3>
        <div className="grid grid-cols-2 gap-4">
          <Button variant="neutral" onClick={handleClose} className="w-full">
            Нет
          </Button>
          <Button variant="primary" onClick={handleConfirm} className="w-full">
            Да
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Sidebar.displayName = 'Sidebar';
}
