import { useMemo, useState } from 'react';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { FavoriteMenuList } from '@/pages/pick-up/FavoriteMenuList';
import { MenuList } from '@/pages/pick-up/MenuList';
import { PickUpConfirmButton } from '@/pages/pick-up/PickUpConfirmButton';
import { PickUpProvider } from '@/pages/pick-up/PickUpProvider';

// =================================================================

const views: Record<number, JSX.Element> = {
  0: <MenuList />,
  1: <FavoriteMenuList />,
};

// =================================================================

export const PickUpPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const tabs = useMemo(() => {
    return [
      {
        label: 'Все',
        onClick: () => {
          setActiveIndex(0);
        },
      },
      {
        label: 'Избранное',
        onClick: () => {
          setActiveIndex(1);
        },
      },
    ];
  }, []);
  return (
    <PickUpProvider>
      <PageTitle title="Сoбой" className="mb-6" />
      <ul className="mb-5 flex items-center gap-4">
        {tabs.map((item, index) => (
          <li key={index}>
            <Button
              variant={activeIndex === index ? 'primary' : 'neutral'}
              onClick={item.onClick}
              className="border-1 join-item"
            >
              {item.label}
            </Button>
          </li>
        ))}
      </ul>
      {views[activeIndex]}
      <div className="mt-6 h-44">
        <PickUpConfirmButton />
      </div>
    </PickUpProvider>
  );
};
