import { useQuery } from '@tanstack/react-query';
import { useState, useEffect, useCallback } from 'react';

import { last, max } from 'lodash';

import { MenuApi } from '@/api/domains/menu.api';

import { MenuQueryKeys } from '@/contexts/QueryContext/query.keys';

type OrderedFavoriteList = {
  id: number | null;
  order: number;
  name: string;
  menuId: number | null;
  price: number | null;
};

export const useFavoriteList = () => {
  const [favoriteList, setFavoriteList] = useState<OrderedFavoriteList[]>([]);

  const { data, isLoading } = useQuery({
    queryKey: MenuQueryKeys.favoriteList(),
    queryFn: MenuApi.fetchFavoriteList,
    staleTime: 0,
    select: data => {
      const transformedData: Record<string, OrderedFavoriteList> = {};

      data.results.forEach(item => {
        transformedData[item.index] = {
          id: item.id,
          order: item.index,
          name: item.menu,
          menuId: item.menu_id,
          price: item.price,
        };
      });

      return {
        list: transformedData,
        count: data.count,
        current_page: data.current_page,
        last_page: data.last_page,
      };
    },
  });

  useEffect(() => {
    if (data) {
      const list: OrderedFavoriteList[] = [];
      const maxOrder = max(Object.keys(data.list).map(item => Number(item)))!;

      for (let i = 1; i <= maxOrder; i++) {
        if (data.list[i]) {
          list.push(data.list[i]);
        } else {
          list.push({
            id: null,
            order: i,
            name: '',
            menuId: null,
            price: null,
          });
        }
      }

      setFavoriteList(list);
    }
  }, [data]);

  const addTable = useCallback(() => {
    setFavoriteList(prev => {
      const lastItem = last(prev)!;

      return [
        ...prev,
        {
          id: null,
          order: lastItem.order + 1,
          name: '',
          menuId: null,
          price: null,
        },
      ];
    });
  }, []);

  return {
    favoriteList,
    isLoading,
    originData: data,
    addTable,
  };
};
