import { useEffect, useState } from 'react';

import type { MenuList } from '@/api/types/menu.types';

import { PickMenuCardUI } from '@/pages/pick-up/PickUpCardUI';
import { usePickUpMethods } from '@/pages/pick-up/PickUpProvider';

// =================================================================

interface PickUpMenuCardProps {
  menu: MenuList;
}

// =================================================================

export const PickUpMenuCard = (props: PickUpMenuCardProps) => {
  const { menu } = props;

  const [count, setCount] = useState(0);

  const { decreaseMenuItem, increaseMenuItem, menus } = usePickUpMethods();

  useEffect(() => {
    if (menus.length === 0) {
      setCount(0);
    }
  }, [menus]);

  const handleDecreament = () => {
    if (count !== 0) {
      setCount(prev => prev - 1);
      decreaseMenuItem(menu.id);
    }
  };

  const handleIncreament = () => {
    setCount(prev => prev + 1);
    increaseMenuItem({
      id: menu.id,
      title: menu.name,
      count: 1,
      poster: menu.photos[0],
      price: menu.price,
    });
  };

  return (
    <PickMenuCardUI
      imageSrc={menu.photos?.[0] || ''}
      title={menu.name}
      handleDecreament={handleDecreament}
      handleIncreament={handleIncreament}
      count={count}
      price={menu.price}
    />
  );
};
