import { useEffect, Fragment, useState } from 'react';

// import { useNetworkState } from 'react-use';
import type { OrderSocketMessage } from '@/api/types/order.types';
import { IPropsChildren } from '@/types/common.types';

import { useAuthState } from '@/contexts/AuthContext';
import { ACCESS_TOKEN } from '@/contexts/AuthContext';

export const PrinterContext = (props: IPropsChildren) => {
  const { children } = props;

  const [socket, setSocket] = useState<WebSocket | null>(null);
  // const [isConnected, setIsConnected] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // const { online } = useNetworkState();
  const { accessToken } = useAuthState();

  // ----------------------------------------------------------------

  const handleSubmit = async (data: OrderSocketMessage) => {
    try {
      await fetch(`${process.env.REACT_APP_LOCAL_IP}/api/printer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      // const resData = await response.json();
      // console.log(resData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // ----------------------------------------------------------------

  const createWebSocket = (token: string) => {
    const url = process.env.REACT_APP_WEB_SOCKET_URL + '/ws/auth?authorization=bearer ' + token;

    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log('Connected to WebSocket');
      // setIsConnected(true);
      setRetryCount(0); // Reset retry count on successful connection
    };

    ws.onmessage = async message => {
      const data = JSON.parse(message.data);
      console.log('Message received:', data);
      await handleSubmit(data);
    };

    ws.onclose = event => {
      console.log('WebSocket closed:', event);
      // setIsConnected(false);
      attemptReconnect(token); // Try to reconnect
    };

    ws.onerror = error => {
      console.error('WebSocket error:', error);
      ws.close(); // Close the connection on error
    };

    setSocket(ws);
  };

  // ----------------------------------------------------------------

  const attemptReconnect = (token: string) => {
    if (retryCount < 5) {
      // Max retry limit (optional)
      const timeout = Math.min(5000, 1000 * Math.pow(2, retryCount)); // Exponential backoff
      console.log(`Reconnecting in ${timeout / 1000} seconds...`);

      setTimeout(() => {
        setRetryCount(retryCount + 1);
        createWebSocket(token);
      }, timeout);
    } else {
      console.log('Max reconnect attempts reached');
    }
  };

  // ----------------------------------------------------------------

  useEffect(() => {
    const persistedToken = localStorage.getItem(ACCESS_TOKEN);
    const token = accessToken || persistedToken;

    if (!token) return;

    createWebSocket(token);

    return () => {
      if (socket) {
        socket.close(); // Cleanup WebSocket on component unmount
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  // ----------------------------------------------------------------

  // const reconnectWebSocket = (token: string | null) => {
  //   if (!token) return;

  //   const url = process.env.REACT_APP_WEB_SOCKET_URL + '/ws/auth?authorization=bearer ' + token;
  //   const socket = new WebSocket(url);

  //   socketRef.current = socket;
  // };

  // useEffect(() => {
  //   if (token) {
  //     reconnectWebSocket(token);

  //     // socketRef.current?.addEventListener('open', () => {
  //     //   socketRef.current?.send('Hello Server!');
  //     // });

  //     socketRef.current?.addEventListener('message', event => {
  //       const data = JSON.parse(event.data);
  //       handleSubmit(data);
  //       console.log('Message from server ', data);
  //     });

  //     socketRef.current?.addEventListener('close', () => {
  //       console.log('socket closed');
  //       setTimeout(() => reconnectWebSocket(token), 100);
  //     });

  //     return () => {
  //       socketRef.current?.close();
  //     };
  //   }
  // }, [token, online]);

  return <Fragment>{children}</Fragment>;
};
