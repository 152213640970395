import { ColumnDef } from '@tanstack/react-table';
import { Fragment, memo, useState } from 'react';

import type { OrderList } from '@/api/types/order.types';
import { OrderStatusEnum } from '@/enums/order.enum';
import { thousandSeperator } from '@/helpers/thousand-separator.helper';

import { useOrderList } from '@/pages/orders/useOrderList';

import { OrderInfoModal } from '@/components/OrderInfoModal';
import { Table } from '@/components/Table';

import { OrderPrintoutButton } from '@/pages/orders/OrderPrintoutButton';
import { OrderSearchBar } from '@/pages/orders/OrderSearchBar';

// =================================================================

const columns: ColumnDef<OrderList>[] = [
  {
    accessorKey: 'created_at',
    header: 'Время',
  },
  {
    header: '№ Заказа',
    accessorKey: 'number',
  },
  {
    header: 'Официант',
    accessorKey: 'waiter_name',
  },
  {
    accessorFn: row => {
      return { tableNumber: row.table_number, isPickUp: row.pick_up };
    },
    cell: props => {
      const { isPickUp, tableNumber } = props.getValue() as {
        tableNumber: number;
        isPickUp: boolean;
      };
      return isPickUp ? 'сoбой' : tableNumber;
    },
    header: 'Стол',
  },
  {
    // accessorKey: 'price',
    // cell: props => `${thousandSeperator(props.getValue() as number)} сум`,
    header: 'Цена',
  },
  {
    accessorKey: 'price',
    cell: props => `${thousandSeperator(props.getValue() as number)} сум`,
    header: 'Общая сумма',
  },
  {
    header: 'Печать',
    accessorFn: row => {
      return { id: row.id, status: row.status };
    },
    cell: props => {
      const { id, status } = props.getValue() as { id: number; status: OrderStatusEnum };

      if (status === OrderStatusEnum.NEW) {
        return <OrderPrintoutButton orderId={id} />;
      }

      return null;
    },
  },
];

// =================================================================

interface OrdersTableProps {
  handleSelectOrder: (orderId: number) => void;
}

// =================================================================

export const OrdersTable = memo((props: OrdersTableProps) => {
  const { handleSelectOrder } = props;

  const [orderId, setOrderId] = useState('');

  const { orders, isLoading, isPlaceholderData, pagination, setPagination } = useOrderList();

  if (!orders && isLoading) {
    return <h2>Loading...</h2>;
  }

  return (
    <Fragment>
      <Table
        columns={columns}
        pageCount={orders!.last_page + 1}
        state={{ pagination }}
        data={orders!.results}
        isLoading={isLoading || isPlaceholderData}
        onPaginationChange={setPagination}
        searchBar={<OrderSearchBar />}
        onClickRow={handleSelectOrder}
      />
      <OrderInfoModal orderId={orderId} isOpen={Boolean(orderId)} onClose={() => setOrderId('')} />
    </Fragment>
  );
});

if (process.env.NODE_ENV !== 'production') {
  OrdersTable.displayName = 'OrdersTable';
}
