import { Fragment, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

import { twMerge } from 'tailwind-merge';

import { OrderStatusEnum } from '@/enums/order.enum';

import { Button } from '@/components/Button';
import { PageTitle } from '@/components/PageTitle';

import { OrderInfo } from '@/pages/orders/OrderInfo';
import { OrdersTable } from '@/pages/orders/OrdersTable';

export const OrdersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [orderId, setOrderId] = useState<number | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffectOnce(() => {
    setSearchParams(searchParams => {
      searchParams.set('page', '1');
      searchParams.set('status', 'new');
      return searchParams;
    });
  });

  const handleSelectOrder = (orderId: number) => {
    setOrderId(orderId);
  };

  const clearOrderId = useCallback(() => {
    setOrderId(null);
  }, []);

  const handleChangeStatus = (status: OrderStatusEnum | null) => {
    if (status) {
      setSearchParams({ status: status.toLowerCase(), page: '1' });
    } else {
      searchParams.delete('status');
      setSearchParams(searchParams);
    }
  };

  const tabs = useMemo(() => {
    return [
      {
        label: 'Открыть',
        onClick: () => {
          handleChangeStatus(OrderStatusEnum.NEW);
          setActiveIndex(0);
        },
      },
      {
        label: 'Закрыто',
        onClick: () => {
          handleChangeStatus(OrderStatusEnum.PAID);
          setActiveIndex(1);
        },
      },
      {
        label: 'Все',
        onClick: () => {
          handleChangeStatus(null);
          setActiveIndex(2);
        },
      },
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <PageTitle title="Заказы" className="mb-6" />
      <ul className="mb-5 flex items-center gap-4">
        {tabs.map((item, index) => (
          <li key={index}>
            <Button
              variant={activeIndex === index ? 'primary' : 'neutral'}
              onClick={item.onClick}
              className="border-1 join-item"
            >
              {item.label}
            </Button>
          </li>
        ))}
      </ul>
      <div className={twMerge('grid grid-cols-1 gap-4', orderId && 'grid-cols-[2fr_1fr]')}>
        <OrdersTable key={activeIndex} handleSelectOrder={handleSelectOrder} />
        <div className=" w-full">
          <OrderInfo orderId={orderId} clearOrderId={clearOrderId} />
        </div>
      </div>
    </Fragment>
  );
};
