export const TableQueryKeys = {
  PREFIX: 'table',

  categoryList: () => [TableQueryKeys.PREFIX, 'category-list'],
  tableList: (hallId?: string | number) => [
    TableQueryKeys.PREFIX,
    'list',
    hallId ? hallId.toString() : 'all',
  ],
} as const;

// =================================================================

export const OrderQueryKeys = {
  PREFIX: 'order',

  orderList: (page: number, limit: number, search: string = '', status: string = 'all') => [
    OrderQueryKeys.PREFIX,
    'list',
    limit,
    page,
    search,
    status,
  ],
  orderDetails: (id: number | string) => [OrderQueryKeys.PREFIX, 'details', id.toString()],
} as const;

// =================================================================

export const MenuQueryKeys = {
  PREFIX: 'menu',

  menuCategory: () => [MenuQueryKeys.PREFIX, 'menu-category'],
  menuList: (id?: string | number | null, search?: string | null) => [
    MenuQueryKeys.PREFIX,
    'menu-list',
    id ? id.toString() : 'all',
    search || 'all',
  ],
  favoriteList: () => [MenuQueryKeys.PREFIX, 'favorite-list'],
} as const;

// =================================================================

export const StatisticsQueryKeys = {
  PREFIX: 'statistics',

  daily: (date: string) => [StatisticsQueryKeys.PREFIX, 'daily', date],
};
