import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { PaymentTypeEnum } from '@/enums/order.enum';

import { Button } from '@/components/Button';
import { NumberInput } from '@/components/Inputs/NumberInput';
import { usePaymentMethods } from '@/components/OrderInfoModal/PaymentProvider';

// =================================================================

type FormValues = {
  cash: string;
  card: string;
};

const initialValues = {
  cash: '',
  card: '',
};

// =================================================================

export const PaymentWithCardAndCash = () => {
  const { isLoading, onPay } = usePaymentMethods();

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: initialValues,
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    const { card, cash } = data;

    if (!card && !cash) return;

    const payments = [] as { price: number; payment_type: PaymentTypeEnum }[];
    if (card) {
      payments.push({ price: Number(card), payment_type: PaymentTypeEnum.CARD });
    }
    if (cash) {
      payments.push({ price: Number(cash), payment_type: PaymentTypeEnum.CASH });
    }

    await onPay(payments);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="cash"
        control={control}
        render={({ field }) => {
          const { onChange, onBlur, ...rest } = field;

          return (
            <NumberInput
              label="Наличные"
              placeholder="Введите сумму"
              className="mb-1"
              thousandSeparator={' '}
              decimalSeparator="."
              autoFocus
              onValueChange={params => {
                onChange(params.value);
              }}
              {...rest}
            />
          );
        }}
      />
      <Controller
        name="card"
        control={control}
        render={({ field }) => {
          const { onChange, onBlur, ...rest } = field;
          return (
            <NumberInput
              label="Полученная сумма (Банковская карта)"
              placeholder="Введите сумму"
              className="mb-3"
              thousandSeparator={' '}
              decimalSeparator="."
              onValueChange={params => {
                onChange(params.value);
              }}
              {...rest}
            />
          );
        }}
      />
      <Button type="submit" variant="primary" disabled={isLoading} className="w-full">
        Оплатить
      </Button>
    </form>
  );
};
